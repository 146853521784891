import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import RelatedProducts from "../../client/compositions/related-products"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { PortfolioTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Project: AubergeResort Page
 * @see https://zpl.io/bzgYOWl
 */
function ProjectAubergeResortPage() {
  return (
    <article
      id="page-portfolio-auberge-resort"
      className="page-content portfolio-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            <sup aria-hidden={true} role="presentation">
              WineTrend Custom Project
            </sup>
            <div>Auberge Resort</div>
          </>
        }
        content={<>Fort Lauderdale, FL</>}
        className="flipped portfolio-banner"
      >
        <DecorationFluid image="portfolio-auberge-resort/banner.jpg" />
      </Banner>

      <Row id="summary" className="content-plus-media flipped">
        <Block className="block-content dropped">
          <div className="content">
            <dl className="details">
              <div className="detail">
                <dt className="title">General Contractor</dt>
                <dd className="content">Moss (Ft. Lauderdale, FL)</dd>
              </div>
              <div className="detail">
                <dt className="title">Millwork Consultants/Designer</dt>
                <dd className="content">
                  Hollywood Woodworks, Inc (Hollywood, FL)
                </dd>
              </div>
              <div className="detail">
                <dt className="title">Bottle Capacity</dt>
                <dd className="content">1800</dd>
              </div>
            </dl>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-auberge-resort/content-01.medium.jpg" />
        </Block>
      </Row>

      <Row id="features" className="content-plus-media">
        <Block className="block-content dropped">
          <h4 className="title">Features</h4>
          <div className="content">
            <ul className="features">
              <li className="feature">
                <strong style={{ fontStyle: "italic" }}>
                  Cigar Room Cabinets
                </strong>
                <br /> Custom cabinets with cedar shelving; warm white leds; and
                humidifiers.
              </li>
              <li className="feature">
                <strong style={{ fontStyle: "italic" }}>Lobby Display</strong>
                <br /> Backlit horizontal shelving with custom finished white
                oak, low profile shelves and 3/8” side hinged tempered glass
                doors.
              </li>
              <li className="feature">
                <strong style={{ fontStyle: "italic" }}>Oval Display</strong>
                <br /> Wine room centerpiece with specially made curved glass
                doors and hinges; custom finished wooden wine racks; backlit
                warm white LEDs; custom finished white out wood base and header.
              </li>
              <li className="feature">
                <strong style={{ fontStyle: "italic" }}>
                  Wine Room Lockers
                </strong>
                <br /> Individual private wine locker storage; pullout drawers
                installed within each locker to allow easy access to storage;
                all doors keyed separately; custom finished rolling library
                ladders.
              </li>
            </ul>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-auberge-resort/content-04.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-two" className="single-media">
        <Block className="block-media">
          <Decoration image="/portfolio-auberge-resort/content-05.jpg" />
        </Block>
      </Row>

      <RelatedProducts
        id="related-products"
        products={[
          {
            slug: "custom-build",
            title: "Custom Build",
            url: "products/custom-build/",
          },
        ]}
      />

      <PortfolioTaglineMenu portfolioSlug="portfolio-auberge-resort" />
    </article>
  )
}

export default ProjectAubergeResortPage
